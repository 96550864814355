import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import SectionNoAnim from "../configurable/SectionNoAnim"
import ReviewsSlider from "~/components/standalone/ReviewsSlider"
import ImageRow from "../configurable/ImageRow"
import Col12 from "../grid/Col12"
import "./reviews.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"

import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"

const ReviewsPage = ({ location }) => {

  return (
    <div className="blank">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Reviewssss
        </Heading>
      </Hero>
      <Block className="segBlock">
        <ReviewsSlider/>
      </Block>

      
    </div>
  )
}

export default ReviewsPage
